@charset "utf-8";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
*::before,
*::after {
  box-sizing: border-box;
}

:where(:not(fieldset, progress, meter)) {
  border-width: 0;
  border-style: solid;
  background-repeat: no-repeat;
  background-origin: border-box;
}
html {
  -webkit-text-size-adjust: none;
  block-size: 100%;
}
@media (prefers-reduced-motion: no-preference) {
  html:focus-within {
    scroll-behavior: smooth;
  }
}
body {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  min-block-size: 100%;
}
:where(img, svg, video, canvas, audio, iframe, embed, object) {
  display: block;
}
:where(img, svg, video) {
  max-inline-size: 100%;
  block-size: auto;
}
:where(svg) {
  stroke: none;
  fill: currentColor;
}
:where(svg):where(:not([fill])) {
  stroke: currentColor;
  fill: none;
  stroke-linecap: round;
  stroke-linejoin: round;
}
:where(svg):where(:not([width])) {
  inline-size: 5rem;
}
:where(input, button, textarea, select),
:where(input[type="file"])::-webkit-file-upload-button {
  color: inherit;
  font: inherit;
  font-size: inherit;
  letter-spacing: inherit;
}
:where(textarea) {
  resize: vertical;
}
@supports (resize: block) {
  :where(textarea) {
    resize: block;
  }
}
:where(p, h1, h2, h3, h4, h5, h6) {
  overflow-wrap: break-word;
}
:where(ul, ol)[role="list"] {
  list-style: none;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
:where(a[href], area, button, input, label[for], select, summary, textarea, [tabindex]:not([tabindex*="-"])) {
  cursor: pointer;
  touch-action: manipulation;
}
:where(input[type="file"]) {
  cursor: auto;
}
:where(input[type="file"])::-webkit-file-upload-button,
:where(input[type="file"])::file-selector-button {
  cursor: pointer;
}
:where(button, button[type], input[type="button"], input[type="submit"], input[type="reset"])[disabled] {
  cursor: not-allowed;
}
:where(button, button[type], input[type="button"], input[type="submit"], input[type="reset"]),
:where(input[type="file"])::-webkit-file-upload-button,
:where(input[type="file"])::file-selector-button {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  text-align: center;
  user-select: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
:where(input, button, textarea, select) {
  appearance: none;
  border: none;
  border-radius: 0;
  background: none;
  font-weight: inherit;
  font-size: inherit;
  font-family: inherit;
}
